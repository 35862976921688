/* 全局字体颜色变量 */
:root {
  --text-primary: #2c3e50;
  --text-secondary: #505c6e;
  --text-light: #647282;
  --text-white: #ffffff;
  --link-color: #3498db;
  --link-hover: #2980b9;
  --heading-color: #1a2634;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  min-height: 100vh;
  position: relative;
  color: var(--text-primary);
  line-height: 1.6;
  transition: background 0.3s ease;
}

.dark-mode {
  color: #ffffff;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--heading-color);
}

a {
  color: var(--link-color);
  transition: color 0.3s ease;
}

a:hover {
  color: var(--link-hover);
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    radial-gradient(circle at 15% 50%, rgba(36, 105, 240, 0.03) 0%, transparent 25%),
    radial-gradient(circle at 85% 30%, rgba(255, 82, 82, 0.03) 0%, transparent 25%);
  pointer-events: none;
}

.app-container {
  position: relative;
  z-index: 1;
}

/* 添加动态背景元素 */
.background-shapes {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
}

.shape {
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(45deg, rgba(66, 133, 244, 0.05), rgba(66, 133, 244, 0.1));
  animation: float 20s infinite ease-in-out;
}

.shape:nth-child(1) {
  width: 300px;
  height: 300px;
  top: 10%;
  left: 5%;
  animation-delay: 0s;
}

.shape:nth-child(2) {
  width: 200px;
  height: 200px;
  top: 60%;
  right: 10%;
  animation-delay: -5s;
  background: linear-gradient(45deg, rgba(234, 67, 53, 0.05), rgba(234, 67, 53, 0.1));
}

.shape:nth-child(3) {
  width: 150px;
  height: 150px;
  bottom: 20%;
  left: 15%;
  animation-delay: -10s;
  background: linear-gradient(45deg, rgba(52, 168, 83, 0.05), rgba(52, 168, 83, 0.1));
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(5deg);
  }
}

/* 卡片通用样式 */
.card {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.15);
}

/* 毛玻璃效果 */
.glass-effect {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.15);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 添加平滑过渡效果 */
* {
  transition: all 0.3s ease;
}

/* 页面切换动画 */
.page-transition-enter {
  opacity: 0;
  transform: translateY(20px);
}

.page-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

/* 交互反馈 */
.button-hover {
  transform: scale(0.98);
  transition: transform 0.2s;
}

/* 加载动画 */
.loading-spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 修改暗黑模式相关样式 */
.dark-mode {
  /* 移除背景色设置，保持原始背景 */
  color: #ffffff;
}

/* 暗黑模式下的卡片样式 */
.dark-mode .card,
.dark-mode .about-content,
.dark-mode .glass-effect {
  background: rgba(70, 71, 73, 0.95);
  border: 1px solid rgba(227, 13, 13, 0.894);
  color: #cf0808;
}

/* 暗黑模式下的标题颜色 */
.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode h5,
.dark-mode h6,
.dark-mode .about-title {
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(8, 248, 172, 0.95);
}

/* 暗黑模式下的文本颜色 */
.dark-mode .about-text {
  color: #f0f0f0;
}

/* 暗黑模式下的卡片阴影效果 */
.dark-mode .card,
.dark-mode .about-content {
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.4),
    0 2px 8px rgba(0, 0, 0, 0.2);
}

/* 暗黑模式下的悬停效果 */
.dark-mode .about-content:hover {
  box-shadow: 
    20px 20px 60px rgba(0, 0, 0, 0.3),
    -20px -20px 60px rgba(255, 255, 255, 0.05),
    0 4px 15px rgba(0, 0, 0, 0.2);
}

/* 暗黑模式下渐变光效 */
.dark-mode .about-content::after {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0.05) 50%,
    rgba(0, 0, 0, 0.1) 100%
  );
}

.about-container {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  perspective: 1000px;
}

.about-content {
  max-width: 800px;
  background: rgba(255, 255, 255, 0.95);
  padding: 3rem;
  border-radius: 20px;
  box-shadow: 
    0 10px 30px rgba(0, 0, 0, 0.1),
    0 20px 60px rgba(0, 0, 0, 0.05),
    0 1px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  transform-style: preserve-3d;
  transition: 
    transform 0.5s ease,
    box-shadow 0.5s ease;
}

.about-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #4ca5ff, #b573f8);
  transform: translateZ(30px);
}

.about-title {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
  transform: translateZ(40px);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.about-text {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #34495e;
  transform: translateZ(20px);
}

.about-text p {
  margin-bottom: 1.5rem;
  opacity: 0;
  animation: fadeInUp 0.8s ease forwards;
}

.about-text p:nth-child(2) {
  animation-delay: 0.2s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateZ(20px);
  }
}

.about-content:hover {
  transform: 
    translateY(-5px) 
    rotateX(5deg) 
    rotateY(5deg);
  box-shadow: 
    20px 20px 60px rgba(0, 0, 0, 0.1),
    -20px -20px 60px rgba(255, 255, 255, 0.8),
    0 4px 15px rgba(0, 0, 0, 0.1);
}

.about-content::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 50%,
    rgba(0, 0, 0, 0.05) 100%
  );
  pointer-events: none;
  transform: translateZ(20px);
}

.about-container:hover .about-content {
  transition: transform 0.3s ease;
}

@media (max-width: 768px) {
  .about-container {
    padding: 1rem;
    perspective: 600px;
  }
  
  .about-content {
    padding: 2rem;
  }
  
  .about-title {
    font-size: 2rem;
  }
  
  .about-text {
    font-size: 1rem;
  }
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.about-content::before {
  animation: shimmer 8s infinite linear;
  background: linear-gradient(
    90deg,
    #4ca5ff 0%,
    #b573f8 50%,
    #4ca5ff 100%
  );
  background-size: 200% 100%;
}
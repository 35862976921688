@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&family=Exo+2:wght@400;600;700&display=swap');

:root {
  --primary-color: #4CAF50;
  --secondary-color: #2196F3;
  --background-color: #0A192F;
  --text-color: #724545;
  --card-bg-color: rgba(16, 36, 69, 0.8);
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Exo 2', sans-serif;
  color: var(--primary-color);
}

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.background-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.glass-nav, .glass-footer {
  background: rgba(16, 36, 69, 0.8);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.glass-nav {
  background: linear-gradient(
    to bottom,
    rgba(16, 36, 69, 0.9),
    rgba(16, 36, 69, 0.7)
  );
  backdrop-filter: blur(12px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 1rem 0;
}

.glass-nav ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  gap: 2rem;
}

.glass-nav li {
  margin: 0;
  position: relative;
}

.glass-nav a {
  color: #a8b2d1;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  font-family: 'Exo 2', sans-serif;
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  position: relative;
}

.glass-nav a:hover {
  color: #64ffda;
  background: rgba(100, 255, 218, 0.1);
}

.glass-nav a.active {
  color: #64ffda;
  font-weight: 600;
}

.glass-nav a::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  background: #64ffda;
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.glass-nav a:hover::after,
.glass-nav a.active::after {
  width: 80%;
}

.content {
  flex-grow: 1;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.glass-footer {
  text-align: center;
  padding: 1rem 0;
  margin-top: auto;
}

.card {
  background: var(--card-bg-color);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(76, 175, 80, 0.2);
}

button, .btn {
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-family: 'Exo 2', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  display: inline-block;
}

button:hover, .btn:hover {
  background-color: var(--secondary-color);
}

input, textarea {
  background-color: rgba(64, 128, 109, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: var(--text-color);
  padding: 0.5rem;
  border-radius: 5px;
}

input:focus, textarea:focus {
  outline: none;
  border-color: var(--primary-color);
}

.grid {
  display: grid;
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .grid-cols-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.text-center {
  text-align: center;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

/* 导航栏样式 */
.nav {
  background: rgba(10, 25, 47, 0.85);
  backdrop-filter: blur(10px);
  padding: 1rem 2rem;
  border-bottom: 1px solid rgba(100, 255, 218, 0.1);
}

.nav-list {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  margin: 0;
  padding: 0;
  list-style: none;
  max-width: 1200px;
  margin: 0 auto;
}

.nav-item {
  position: relative;
}

.nav-link {
  color: #ccd6f6;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0.5rem 0;
  transition: all 0.3s ease;
  font-family: 'Exo 2', sans-serif;
  letter-spacing: 0.5px;
}

.nav-link:hover {
  color: #64ffda;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: #64ffda;
  transition: width 0.3s ease;
}

.nav-link:hover::after {
  width: 100%;
}

/* 活动链接样式 */
.nav-link.active {
  color: #64ffda;
  font-weight: 600;
}

.nav-link.active::after {
  width: 100%;
}
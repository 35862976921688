.weekly-video-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
}

.weekly-video-container.dark {
  background-color: #121212;
  color: #ffffff;
}

.weekly-video-container h1 {
  text-align: center;
  color: var(--heading-color);
  margin-bottom: 2rem;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.video-card {
  background: white;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: center;
}

.video-card:hover {
  transform: translateY(-5px);
}

.thumbnail-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 比例 */
}

.video-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background: rgba(0,0,0,0.7);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.play-button i {
  color: white;
  font-size: 24px;
}

.thumbnail-container:hover .play-button {
  opacity: 1;
}

.video-info {
  padding: 1.5rem;
}

.video-info h2 {
  color: var(--heading-color);
  margin: 0 0 1rem;
  font-size: 1.2rem;
}

.date {
  color: var(--text-light);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.description {
  color: var(--text-secondary);
  margin-bottom: 1rem;
  line-height: 1.5;
}

.watch-button {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background: #ff0000;
  color: var(--text-white);
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.watch-button:hover {
  background: #cc0000;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 2rem;
}

.pagination button {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  background: white;
  color: var(--text-secondary);
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.pagination button.active {
  background: var(--link-color);
  color: var(--text-white);
  border-color: #007bff;
}

.pagination button:hover:not(.active) {
  background: #f8f9fa;
}

.btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .video-grid {
    grid-template-columns: 1fr;
  }
  
  .weekly-video-container {
    padding: 1rem;
  }
}

.video-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 添加其他样式以适应视频分享版面 */

.toggle-mode {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-mode:hover {
  background-color: #0056b3;
}

/* 标题样式 */
.weekly-video-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: var(--heading-color);
  position: relative;
  text-align: center;
}

/* 暗黑模式下的标题样式 */
.dark-mode .weekly-video-title {
  color: #ffffff;
  text-shadow: 
    0 2px 4px rgba(0, 0, 0, 0.3),
    0 0 10px rgba(255, 255, 255, 0.1);
  background: linear-gradient(to right, #e0e0e0, #ffffff);
  -webkit-background-clip: text;
  background-clip: text;
  /* 保留这行是为了确保渐变效果在某些浏览器中正常显示 */
  -webkit-text-fill-color: transparent;
}

/* 确保标题在暗黑模式下有足够的对比度 */
.dark-mode .weekly-video-container {
  position: relative;
}

.dark-mode .weekly-video-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 150px; /* 调整高度以覆盖标题区域 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), transparent);
  pointer-events: none;
}
.home {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: transparent;
}

.home h1 {
  color: #333;
  margin-bottom: 2rem;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.dark .home h1 {
  color: #dd1212;
}

.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.card {
  background: white;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.card:hover {
  transform: translateY(-10px) rotate(2deg);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 123, 255, 0.1);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.card:hover::before {
  opacity: 1;
}

.dark .card {
  background: #d50a0a;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.card h2 {
  color: #333;
  margin-bottom: 1rem;
}

.dark .card h2 {
  color: #fff;
}

.card p {
  color: #666;
  margin-bottom: 1rem;
}

.dark .card p {
  color: #bbb;
}

.btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.dark .btn {
  background-color: #0056b3;
}

.dark .btn:hover {
  background-color: #003d80;
}

.search-input {
  color: #000;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 16px;
}

.dark .search-input {
  color: #fff;
  background-color: #333;
  border: 1px solid #555;
}

.search-input:focus {
  border-color: #007bff;
  outline: none;
}

.dark .search-input:focus {
  border-color: #0056b3;
} 
.navbar {
  background: linear-gradient(to right, #4b6cb7, #182848);
  padding: 10px;
}

.navbar ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
}

.navbar li {
  margin: 0 15px;
}

.navbar a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.navbar a:hover {
  text-decoration: underline;
} 
.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.contact-info {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 3rem 0;
}

.contact-item {
  flex: 1;
  min-width: 250px;
  margin: 1rem;
  padding: 2rem;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.contact-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 25px rgba(0,0,0,0.3);
}

.contact-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, 
    rgba(255,255,255,0.1) 0%,
    rgba(255,255,255,0.2) 50%,
    rgba(255,255,255,0.1) 100%);
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}

.contact-item:hover::before {
  transform: translateX(100%);
}

.contact-item i {
  font-size: 2.5rem;
  color: white;
  margin-bottom: 1rem;
}

.contact-item h3 {
  margin: 1rem 0;
  color: white;
  font-weight: 600;
}

.contact-item p {
  color: rgba(255, 255, 255, 0.9);
  margin: 0.5rem 0;
}

.contact-item:nth-child(1) {
  background: linear-gradient(135deg, #FF6B6B, #FF8E53);
}

.contact-item:nth-child(2) {
  background: linear-gradient(135deg, #4E65FF, #92EFFD);
}

.contact-item:nth-child(3) {
  background: linear-gradient(135deg, #45B649, #96E6A1);
}

.social-media {
  margin-top: 3rem;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 1.5rem;
}

.social-icon {
  font-size: 2.5rem;
  color: #666;
  transition: all 0.3s ease;
}

.social-icon:hover {
  color: #007bff;
  transform: scale(1.2);
}

@media (max-width: 768px) {
  .contact-info {
    flex-direction: column;
  }
  
  .contact-item {
    margin: 1rem 0;
  }
}

/* 添加暗黑模式的样式 */
@media (prefers-color-scheme: dark) {
  .contact-container {
    color: #e1e1e1;
    background-color: #1a1a1a;
  }

  .contact-item {
    background-color: #2d2d2d;
    border: 1px solid #404040;
  }

  .contact-item h3 {
    color: #ffffff;
  }

  .contact-item p {
    color: #cccccc;
  }

  .social-media h2 {
    color: #ffffff;
  }

  .social-icon {
    color: #e1e1e1;
  }

  .social-icon:hover {
    color: #ffffff;
    transform: scale(1.1);
  }
}

[data-theme='dark'] .contact-container {
  /* 使用上面相同的样式 */
} 
.blog-detail {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.blog-detail.dark {
  background-color: #1a1a1a;
  color: #ffffff;
}

.blog-detail-container {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dark .blog-detail-container {
  background-color: #2d2d2d;
}

.back-button {
  display: inline-block;
  margin-bottom: 20px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.back-button:hover {
  background-color: #0056b3;
}

.meta-info {
  margin: 20px 0;
  color: #666;
}

.dark .meta-info {
  color: #aaa;
}

.date {
  margin-right: 15px;
}

.tags {
  display: inline-flex;
  gap: 8px;
}

.tag {
  background-color: #e9ecef;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
}

.dark .tag {
  background-color: #404040;
}

.content {
  line-height: 1.8;
}

.content p {
  margin-bottom: 1.5em;
} 